@import "libs/assets/theme/coreVariablesV2.scss";@import "libs/assets/theme/fonts/index.scss";
@import 'libs/assets/theme/coreVariablesV2.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.subHeading {
  font-size: 1.25rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card {
  background: $white;
  padding: 1rem;
  border-radius: 4px;
}

.dropdownLabel {
  color: $darkGray55K;
  font-weight: unset;
}

.actionBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
